* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --primaryColor: #32c273;
  --mainWhite: #fff;
  --offWhite: #000000;
  --mainBlack: #777676;
  --mainGrey: #40d181;
  --darkGrey: #eefff6;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px #3f3f3f80;
  --darkShadow: 4px 10px 5px 0px #3f3f3f80;
}
/* globals */
/* width */

body {
  padding-top: 66px;
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  line-height: 1.4;
}
h1 {
  font-size: 3em;
  line-height: 1;
  margin-bottom: 0.5em;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1.5em;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 1.2em;
  line-height: 1.25;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}

p,
td,
tr,
a {
  font-size: 1em !important;
}

.sassy {
  width: 13rem;
}

.mediapic {
  width: 20rem;
  border-radius: 0%;
}

.avatar {
  width: 18rem;
  display: block;
  margin: 6rem auto 2rem;
  border-radius: 50%;
}

.employee-box {
  text-align: center;
}

.employee {
  display: block;
  margin: 1rem auto 1rem;
  font-size: 1.5rem !important;
}

.employeemedia {
  display: block;

  font-size: 1.5rem !important;
}

.friend {
  width: 18rem;
  display: block;
  margin: 0rem auto;
}
.booksy {
  width: 13rem;
  display: block;
  margin: 3rem auto 4rem;
}
.jameson {
  width: 11rem;
  display: block;
  margin: 4rem auto;
  margin-bottom: 7rem;
  margin-top: 6rem;
}
.supply {
  width: 12rem;
  display: block;
  margin: 5rem auto 3rem;
}
.reuzel {
  width: 9rem;
  display: block;
  margin: 2rem auto;
  margin-bottom: 5rem;
}
.uppercut {
  width: 10rem;
  display: block;
  margin: 2rem auto;
}

.okocim {
  width: 15rem;
  display: block;
  margin: 0rem auto 4rem;
}

.mk {
  width: 10rem;
  display: block;
  margin: 2rem auto;
}

.btn-primary {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: #fff;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 1rem;
}

.btn-primary-insta {
  display: inline-block;
  text-decoration: none;
  letter-spacing: var(--mainSpacing);
  color: #fff;
  background: var(--primaryColor);
  padding: 0.4rem 0.9rem;
  border: 3px solid var(--primaryColor);
  transition: var(--mainTransition);
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 4rem;
}

.btn-primary:hover {
  background: transparent;
  color: var(--primaryColor);
  border: 3px solid var(--primaryColor);
  text-decoration: none;
}

.btn-primary-insta:hover {
  background: transparent;
  color: var(--primaryColor);
  border: 3px solid var(--primaryColor);
  text-decoration: none;
}
.loading {
  text-transform: capitalize;
  text-align: center;
  margin-top: 2.5rem;
}

.greenload {
  width: 15rem;
}

.error {
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0;
}
.empty-search {
  text-align: center;
  text-transform: capitalize;
  margin: 2rem 0;
  padding: 1rem;
  letter-spacing: var(--mainSpacing);
}
/* end of globals */
/* Navbar */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.75rem 2rem;
  background: var(--offWhite);
  z-index: 1;
  opacity: 0.88;
}
.nav-header {
  display: flex;
  justify-content: space-between;
}

.nav-header img {
  width: 8rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}

.nav-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
.hamburger-react {
  font-size: 1rem;
  color: var(--primaryColor);
}
.nav-center {
  justify-content: center;
  align-items: center;
  width: 100%;
}
.nav-center .nav-links {
  align-items: flex-end;
}
.nav-links {
  height: 0;
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
  margin-bottom: 0;
}
.nav-links a {
  display: block;
  text-decoration: none;
  padding: 1rem 0;
  color: #d1d1d1;
  transition: var(--mainTransition);
  text-align: right;
  font-size: 0.85rem !important;
  font-weight: 600;
  letter-spacing: var(--mainSpacing);
}
.nav-links a:hover {
  color: var(--primaryColor);
}

.show-nav {
  height: 100vh;
}
@media screen and (min-width: 935px) {
  .nav-btn {
    display: none;
  }
  .nav-center {
    max-width: 1170px;
    margin: 0 auto;
    display: flex;
  }
  .nav-links {
    height: auto;
    display: flex;
    margin-left: 4rem;
  }
  .nav-links a {
    margin: 0 1rem;
    padding: 0.5rem 0;
  }
}
/* end of navbar */
/* Hero */
.defaultHero,
.roomsHero,
.roomsHero2,
.roomsHero3,
.roomsHero5 {
  min-height: 100vh;
  background: url("./images/hero2.jpg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomsHero7 {
  min-height: 75vh;
  background: url("./images/hero7.jpg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomsHero4 {
  min-height: 75vh;
  background: url("./images/hero4.jpg") center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.roomsHero3 {
  background-image: url("./images/barber1.jpg");
  min-height: 75vh;
}

.roomsHero2 {
  background-image: url("./images/hero2.jpg");
  min-height: 75vh;
}

.roomsHero5 {
  background-image: url("./images/hero5.jpg");
  min-height: 75vh;
}
/* End of Hero */
/* Banner */
.banner {
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
  color: var(--mainWhite);
  padding: 2rem 4rem;
  text-align: center;

  letter-spacing: var(--mainSpacing);
}
.banner h1 {
  font-size: 2.3rem;
}
.banner div {
  width: 10rem;
  height: 5px;
  background: var(--primaryColor);
  margin: 1.7rem auto;
}
.line-wrap h1 {
  opacity: 1;
}
.banner p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
@media screen and (min-width: 300px) {
  .banner {
    padding: 2rem 2rem;
  }
  .banner h1 {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 576px) {
  .banner {
    padding: 2rem 2rem;
  }
  .banner h1 {
    font-size: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .banner {
    padding: 2rem 6rem;
  }
  .banner h1 {
    font-size: 4rem;
  }
}
/* End of Banner */
/* Title */
.section-title {
  text-align: center;
  margin-bottom: 4rem;
}
.section-title h4 {
  font-size: 2rem;
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.section-title div {
  width: 5rem;
  height: 5px;
  margin: 0 auto;
  background: var(--primaryColor);
}
/* end of Title */

/* services */
.services {
  padding: 5rem 0;
}
.services {
  background: var(--darkGrey);
  text-align: center;
}

.services2 {
  padding: 5rem 2rem;
}
.services2 {
  background: var(--darkGrey);
  text-align: left;
}
.services2 a {
  color: #32c273;
}
.praca {
  color: #32c273;
}

.services3 {
  padding: 5rem 0;
}
.services3 {
  background: #fff;
}
.services-center {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.service span {
  display: inline-block;
  color: var(--primaryColor);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h6 {
  letter-spacing: var(--mainSpacing);
  text-transform: capitalize;
}
.services p {
  width: 80%;
  margin: 0 auto;
}
@media screen and (min-width: 992px) {
  .services-center {
    width: 95vw;
    max-width: 1170px;
  }
}

@media screen and (min-width: 1200px) {
  .services p {
    width: 100%;
  }
}
/*end of services */
/* featured rooms */

.featured-rooms {
  padding: 5rem 0;
}
.featured-rooms-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}

.media-box {
  width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-row-gap: 1.5rem;
  grid-column-gap: 30px;
  margin-top: 5rem;
}

@media screen and (min-width: 776px) {
  .media-box .featured-rooms-center {
    width: 90vw;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
@media screen and (min-width: 992px) {
  .media-box .featured-rooms-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end pf featured rooms */
/* room */
.room {
  box-shadow: var(--lightShadow);
  transition: var(--mainTransition);
  background-color: #000;
}
.room:hover {
  box-shadow: var(--darkShadow);
}

.img-container {
  position: relative;
}
.img-container img {
  width: 100%;
  display: block;
  transition: var(--mainTransition);
}
.price-top {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  color: var(--mainWhite);
  padding: 0.3rem 0.6rem 0.5rem;
  border-bottom-right-radius: 1rem;
  font-size: 0.5rem;
  text-align: center;
  transition: var(--mainTransition);
}
.price-top h6 {
  margin-bottom: 0;
  font-size: 0.9rem;
  font-weight: 300;
  letter-spacing: var(--mainSpacing);
}
.room-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(0);
  transition: all 0.3s linear;
}
.img-container:hover {
  background: rgba(0, 0, 0, 0.8);
}
.img-container:hover img {
  opacity: 0.3;
}
.img-container:hover .price-top {
  opacity: 0;
}
.img-container:hover .room-link {
  transform: translate(-50%, -50%) scale(1);
}
.room-info {
  background: var(--offWhite);
  text-transform: capitalize;
  padding: 0.5rem 0;
  text-align: center;
  font-weight: 700;
  letter-spacing: var(--mainSpacing);
}

p {
  margin-bottom: 0 !important;
}
/* end of room  */
/* single room*/

.single-room {
  padding: 5rem 0 0 0;
}
.single-room-images {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 50px;
}
.single-room-images img {
  width: 100%;
  display: block;
}
.single-room-info {
  width: 80vw;
  display: grid;
  grid-template-columns: 1fr;
  margin: 2rem auto;
}
.desc,
.info {
  margin: 1rem 0;
}
.desc h3 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.desc p {
  line-height: 1.5;
}
.info h3,
.info h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}

.info h6 {
  font-weight: 300;
}
.room-extras {
  width: 80vw;
  margin: 0 auto 3rem auto;
}
.room-extras h6 {
  text-transform: capitalize;
  letter-spacing: var(--mainSpacing);
}
.extras {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
}
@media screen and (min-width: 992px) {
  .single-room-images,
  .single-room-info,
  .room-extras {
    width: 95vw;
    max-width: 1170px;
  }
  .single-room-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 2rem;
  }
  .info {
    padding-left: 3rem;
  }
}
/* end of single room*/
/* roomlist */
.roomslist {
  padding: 5rem 0;
}
.roomslist-center {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 30px;
}

@media screen and (min-width: 776px) {
  .roomslist-center {
    width: 90vw;
  }
}
@media screen and (min-width: 992px) {
  .roomslist-center {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of roomlist */
/*  rooms fitler*/
.filter-container {
  padding: 5rem 0;
}
.filter-form {
  width: 60vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
  grid-row-gap: 2rem;
  grid-column-gap: 40px;
}
.form-group {
  text-transform: capitalize;
}
.form-group label {
  display: block;
  letter-spacing: var(--mainSpacing);
  margin-bottom: 0.5rem;
}
.form-control {
  width: 100%;
  background: transparent;
  font-size: 1rem;
}
.size-inputs {
  display: flex;
}
.size-input {
  width: 40%;
  padding: 0.2rem;
  border: 1px solid var(--mainBlack);
  border-radius: 0.3rem;
  margin-right: 0.3rem;
}
.single-extra label {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}
@media screen and (min-width: 776px) {
  .filter-form {
    width: 70vw;
  }
}
@media screen and (min-width: 992px) {
  .filter-form {
    width: 95vw;
    max-width: 1170px;
  }
}
/* end of rooms fitler*/
/* footer */

footer {
  padding: 100px 0 45px;
  background: var(--offWhite);
  display: flex;
  justify-content: center;
}

.about {
  margin-top: 30px;
  padding-right: 40px;
  color: #d1d1d1;
}

.about {
  font-size: 14px;
  line-height: 23px;
}

.links h4 {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  color: #d1d1d1;
}

.links ul {
  margin: 35px 0 0 0;
}

ul li {
  line-height: 30px;
  list-style: none;
}

a {
  font-size: 14px;
  color: #d1d1d1;
}

a:hover,
a:active {
  color: var(--primaryColor);
}

.social {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  margin-left: 30px;
}
.social img {
  margin-left: 20px;
}

.copyright {
  text-align: center;
  margin-top: 90px;
  color: #d1d1d1;
}

p {
  font-size: 13px;
}

@media screen and (max-width: 760px) {
  div .container {
    width: 100% !important;
  }
  .row {
    flex-direction: column !important;
    align-items: center;
  }

  .widget__item {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .links {
    text-align: center;
  }

  .about {
    margin: 3rem 0;
    padding: 0;
  }

  .social {
    margin: 3rem 0;
    justify-content: space-around;
  }
}

/* end footer*/
/* page transition */

.page {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
}

.fade-appear .fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active .fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 200ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms linear;
}

/* end page transition */
